import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  HStack,
  Input,
  Popover,
  Tag,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  UnorderedList,
  ListItem,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link,
  useDisclosure,
  VStack,
  Image
} from "@chakra-ui/react";
import { IContent, ILibraryContent, IMSBContent } from "../../../../models/content";
import { ContentCard } from "./contentCard";
import { useAuth } from "../../../../provider/authentication";

import { NoContentFound } from "../../../ui/no-content/noContentFound";
import { IContentCategory } from "../../../../models/contentCategory";
import { useRouter } from "next/router";
import { ContentListHeroArea } from "./contentListHeroArea";

import ScrollToTopButton from "../../../utilities/scrollToTopButton";
import LoadingSpinner from "../../../ui/loadingSpinner/loadingSpinner";
import { VIL_OWNER_ID } from "../../../../utils/helper";
import { ContentListTagSelection } from "./marketplace/contentListTagSelection";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CubeIcon } from "@heroicons/react/outline";
import { GlobeAltIcon, PlusIcon, VideoCameraIcon } from "@heroicons/react/solid";
import { MdPublic, MdPublicOff } from "react-icons/md";
import { FaShare } from "react-icons/fa";
export interface ITopTag {
  name: string;
  counter: number;
}

type ContentListProps = {
  content: IContent[];
  msbContent?: IContent[];
  renderFilter: boolean;
  hasMSBContentAcess?: boolean;
  isLoadingContent?: boolean;
  showHeroArea?: boolean;
};

const libraryInfo = {
  html: (
    <Box>
      <Text fontFamily="Bitter" color={"darkBlue.500"} fontWeight={600} fontSize={"2xl"} textAlign={"center"} my={6}>
        Gut zu wissen!
      </Text>
      <UnorderedList flexDirection={"column"} gap={2}>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Die Mediathek der VIL ist eine Sammlung von VR-Anwendungen, 360° Videos und Web Apps unterschiedlicher öffentlicher und
          privater Anbieter
        </ListItem>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Der Fokus diese digitalen Lehr- und Lerninhalte liegt im Bereich der Bildungsangebote allgemeinbildender Schulen
        </ListItem>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Alle Eigentumsrechte der hier aufgeführten Anwendungen, Videos und Webseiten liegen bei den jeweiligen Herausgebern und
          Entwicklerstudios
        </ListItem>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Die Inhalte wurden fachlich geprüft und die für Nutzer*innen hilfreichen Informationen und Metadaten übersichtlich
          zusammengefasst
        </ListItem>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Für öffentliche Bildungseinrichtungen ist die Nutzung dieser Mediathek als VIL Kunde kostenfrei
        </ListItem>
      </UnorderedList>
    </Box>
  )
};
const msbLibraryInfo = {
  html: (
    <Box>
      <Text fontFamily="Bitter" color={"darkBlue.500"} fontWeight={600} fontSize={"2xl"} textAlign={"center"} my={6}>
        Gut zu wissen!
      </Text>
      <UnorderedList flexDirection={"column"} gap={2}>
        <ListItem gap={4} alignContent={"center"} justifyContent={"center"}>
          Die NRW-Bildungsmediathek kann zusätzlich zu der von der VIL bereitgestellten Sammlung verwendet werden.
        </ListItem>
      </UnorderedList>
    </Box>
  )
};

export const ContentList: React.FC<ContentListProps> = ({
  content,
  msbContent = [],
  renderFilter,
  hasMSBContentAcess,
  isLoadingContent = false,
  showHeroArea = false
}) => {
  const { user, isAuthenticated } = useAuth();

  const CURRENT_TOP_TAG_LIMIT = 9;
  const [categoryFilterState, setCategoryFilterState] = useState<Array<IContentCategory>>([]);
  const [typeFilterState, setTypeFilterState] = useState<Array<string>>([]);

  const [ownershipFilterState, setOwnershipFilterState] = useState<Array<string>>([]);
  const [contentSearchString, setContentSearchString] = useState("");
  const [tagSearchString, setTagSearchString] = useState("");
  const [showOwnContent, setShowOwnContent] = useState(false);
  const [localContentList, setLocalContentList] = useState<Array<IContent>>([]);

  const [showTagArea, setShowTagArea] = useState(false);

  const [modal, setModal] = useState(libraryInfo);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const router = useRouter();
  const atleastOneFilterIsSelected =
    categoryFilterState.length > 0 ||
    typeFilterState.length > 0 ||
    ownershipFilterState.length > 0 ||
    contentSearchString.length > 0;

  const resetFilters = () => {
    if (categoryFilterState.length > 0) {
      setCategoryFilterState([]);
    }
    if (typeFilterState.length > 0) {
      setTypeFilterState([]);
    }
    if (ownershipFilterState.length > 0) {
      setOwnershipFilterState([]);
      setShowOwnContent(false);
    }
    if (contentSearchString.length > 0) {
      setContentSearchString("");
    }
    setShowOwnContent(false);

    clearCache();
  };

  const toggleCachedCategories = (cats: IContentCategory[]) => {
    setCategoryFilterState(cats);
    saveFiltersToCache("vilPortal--categories", cats);
  };

  const toggleCategory = (category: any) => {
    let updatedCategories = categoryFilterState.slice();
    const objectIsIncluded = updatedCategories.some(obj => contentCategoryIsEqual(obj.name, category.name));
    if (objectIsIncluded) {
      updatedCategories = updatedCategories.filter(obj => {
        return !contentCategoryIsEqual(category.name, obj.name);
      });
      setCategoryFilterState(updatedCategories);
    } else {
      updatedCategories.push(category);
      setCategoryFilterState(updatedCategories);
    }
  };

  const contentCategoryIsEqual = (a: string, b: string): boolean => {
    return a === b; // Add more checks if needed
  };

  const showInfoPannel = (type: number) => {
    if (type === 1) {
      setModal(libraryInfo);
    }

    onOpen();
  };

  const showContentBasedOnOwnership = (content: ILibraryContent | IMSBContent) => {
    const showPrivate = ownershipFilterState.includes("private");
    const showPublic = ownershipFilterState.includes("public");
    const showShared = ownershipFilterState.includes("shared");
    const showMSB = ownershipFilterState.includes("MSB");

    if (showPublic && showPrivate && showShared) {
      return true;
    }
    if (showPublic && showPrivate) {
      return true;
    }

    if (showPublic) {
      return content.owner && content.owner.id === VIL_OWNER_ID;
    }

    if (showPrivate) {
      return content.owner && user?.firstOrganisation!.id === content.owner.id && !("arixId" in content);
    }
    if (showShared) {
      return content.owner && user?.firstOrganisation!.id != content.owner.id && content.owner.id != VIL_OWNER_ID;
    }

    if (showMSB) {
      return "arixId" in content;
    }
    return false;
  };

  const handleTypeFilterChange = (filterState: Array<string>) => {
    setTypeFilterState(filterState);
    saveFiltersToCache("vilPortal--contentType", filterState);
  };
  const returnFormattedCategories = (contentList: any[]) => {
    const createUniqueCategories = (contentList: any) => {
      let uniqueCategories: ITopTag[] = [];
      contentList.forEach((content: IContent) => {
        if ("arixId" in content) {
          const msbContent: any = content;

          msbContent.arixTags.forEach((category: string) => {
            const objectIsIncluded = uniqueCategories.some(obj => contentCategoryIsEqual(obj.name, category));
            if (!objectIsIncluded) {
              uniqueCategories.push({ name: category.trim(), counter: 0 });
            }
          });
        } else if (content.contentCategories) {
          content.contentCategories.forEach((category: IContentCategory) => {
            const objectIsIncluded = uniqueCategories.some(obj => contentCategoryIsEqual(obj.name, category.name));
            if (!objectIsIncluded) {
              uniqueCategories.push({ name: category.name, counter: 0 });
            }
          });
        }
      });
      return uniqueCategories;
    };

    const returnSortedCategoriesWithCounter = (categories: ITopTag[]) => {
      let uniqueObjects: ITopTag[] = [];
      categories.forEach(el => {
        let counter = 0;
        contentList.forEach((content: any) => {
          let targetArray = [];
          if ("arixId" in content) {
            targetArray = content.arixTags;
          } else {
            targetArray = content.contentCategories;
          }

          const objectIsIncluded = targetArray.some((obj: any) => {
            if (obj.name) {
              return contentCategoryIsEqual(obj.name, el.name);
            } else {
              return contentCategoryIsEqual(obj.trim(), el.name);
            }
          });
          if (objectIsIncluded) {
            counter++;
          }
        });
        el.counter = counter;
        uniqueObjects.push(el);
      });
      uniqueObjects.sort((a, b) => {
        if (a.counter < b.counter) {
          return 1;
        }
        if (a.counter > b.counter) {
          return -1;
        }
        return 0;
      });

      return uniqueObjects;
    };

    const uniqueCategories = createUniqueCategories(contentList);

    const categoriesWithCounter = returnSortedCategoriesWithCounter(uniqueCategories);
    const uniqueItems = categoriesWithCounter.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));
    return uniqueItems;
  };
  const handleOwnershipFilterChange = (filterState: Array<string>) => {
    setOwnershipFilterState(filterState);
    saveFiltersToCache("vilPortal--ownership", filterState);
  };

  const filteredContent = useMemo(() => {
    if (
      categoryFilterState.length === 0 &&
      typeFilterState.length === 0 &&
      ownershipFilterState.length === 0 &&
      contentSearchString.length === 0
    ) {
      return localContentList;
    }

    let content = localContentList.slice();
    content = content.filter((singleContent: any) => {
      let hasValidCategories = categoryFilterState.length === 0;
      if (!hasValidCategories) {
        hasValidCategories = categoryFilterState.every((entry: IContentCategory) => {
          if (singleContent.arixTags) {
            return singleContent.arixTags.some((obj: string) => contentCategoryIsEqual(obj.trim(), entry.name));
          }

          return singleContent.contentCategories.some((obj: IContentCategory) => contentCategoryIsEqual(obj.name, entry.name));
        });
      }
      const lowerCaseSearchString = contentSearchString.toLowerCase();

      return (
        hasValidCategories &&
        (ownershipFilterState.length === 0 || showContentBasedOnOwnership(singleContent)) &&
        (typeFilterState.length === 0 || typeFilterState.includes(singleContent.contentType!)) &&
        (contentSearchString === "" ||
          [
            singleContent.title,
            singleContent.licensor,
            ...(singleContent.contentCategories || []).map((entry: IContentCategory) => entry.name),
            ...(singleContent.arixTags || []),
            ...(singleContent.arixSubjectAreas || [])
          ].some(field => field?.toLowerCase().includes(lowerCaseSearchString)))
      );
    });
    return content;
  }, [localContentList, categoryFilterState, typeFilterState, ownershipFilterState, contentSearchString]);

  const dynamicTopTags = useMemo(() => {
    if (!localContentList) return [];
    return returnFormattedCategories(localContentList).slice(0, CURRENT_TOP_TAG_LIMIT);
  }, [localContentList]);

  const dynamicFilteredCategories = useMemo(() => {
    return returnFormattedCategories(filteredContent);
  }, [filteredContent]);

  const filteredTags = useMemo(() => {
    if (tagSearchString.length === 0 && typeFilterState.length === 0) {
      return dynamicFilteredCategories;
    }
    const categories = dynamicFilteredCategories.filter((singleTag: any) => {
      return (
        (tagSearchString === "" && categoryFilterState.length === 0) ||
        singleTag.name.toLowerCase().includes(tagSearchString.toLowerCase()) ||
        categoryFilterState.some(obj => contentCategoryIsEqual(obj.name, singleTag.name))
      );
    });
    return categories;
  }, [tagSearchString, typeFilterState, dynamicFilteredCategories, categoryFilterState]);

  // Saving array to cache
  const saveFiltersToCache = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  // Retrieving array from cache
  const getFiltersFromCache = (key: string) => {
    const cacheData = localStorage.getItem(key);
    return cacheData ? JSON.parse(cacheData) : null;
  };

  const clearCache = () => {
    Object.keys(localStorage).forEach(key => {
      // Check if the key matches your criteria
      if (key.startsWith("vilPortal")) {
        localStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      resetFilters();
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (msbContent && msbContent.length > 0) {
      const mergedArray = content.concat(msbContent);
      mergedArray.sort((a, b) => a.diffInDays! - b.diffInDays!);
      setLocalContentList(mergedArray);
    } else {
      setLocalContentList(content);
    }
  }, [content, msbContent]);

  useEffect(() => {
    const cachedTags = getFiltersFromCache("vilPortal--categories");
    if (cachedTags) {
      toggleCachedCategories(cachedTags);
    }
    const cachedContentType = getFiltersFromCache("vilPortal--contentType");
    if (cachedContentType) {
      setTypeFilterState(cachedContentType);
    }
    const showOwnContent = getFiltersFromCache("vilPortal--showOwnContent");
    if (showOwnContent) {
      let userOrgID = String(user?.firstOrganisation?.id);
      setOwnershipFilterState([userOrgID]);
      setShowOwnContent(true);
    }
  }, [user?.firstOrganisation?.id]);

  return (
    <>
      {showHeroArea && (
        <ContentListHeroArea
          showTagArea={showTagArea}
          setShowTagArea={setShowTagArea}
          showInfoPannel={showInfoPannel}
          topTags={dynamicTopTags}
        />
      )}
      <ScrollToTopButton />
      <Box maxW={"90%"} mx={"auto"} p={2}>
        {showTagArea && (
          <ContentListTagSelection
            categoryFilterState={categoryFilterState}
            contentCategoryIsEqual={contentCategoryIsEqual}
            filteredTags={filteredTags}
            setShowTagArea={setShowTagArea}
            setTagSearchString={setTagSearchString}
            showTagArea={showTagArea}
            tagSearchString={tagSearchString}
            toggleCategory={toggleCategory}
          />
        )}

        <Box mt={4} minH={"50vh"} width={"full"} rounded={"lg"}>
          {isLoadingContent ? (
            <Box mt={24} w={"full"} h={"full"} textAlign={"center"}>
              <LoadingSpinner text={"Inhalte werden geladen"} />
            </Box>
          ) : (
            <>
              {renderFilter && (
                <>
                  <Flex align={"center"}>
                    <Input
                      value={contentSearchString}
                      borderRadius="2xl"
                      size="sm"
                      w={"20rem"}
                      placeholder={"Suchbegriff eingeben..."}
                      type={"text"}
                      onInput={(e: any) => {
                        setContentSearchString(e.target.value);
                      }}
                    />
                    <HStack w={"full"} ml={2} spacing={"2"}>
                      <Popover>
                        <PopoverTrigger>
                          <Button
                            colorScheme={"darkBlue"}
                            borderRadius={"3xl"}
                            bgColor={typeFilterState.length <= 0 ? "white" : "none"}
                            size="sm"
                            variant={typeFilterState.length > 0 ? "solid" : "outline"}
                          >
                            Inhaltstyp <ChevronDownIcon w={"4"} h={"4"} />
                          </Button>
                        </PopoverTrigger>

                        <PopoverContent borderRadius={"3xl"}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Inhaltstyp auswählen</PopoverHeader>
                          <PopoverBody w={"full"}>
                            <Container p={"4"} w={"full"}>
                              <CheckboxGroup
                                defaultValue={typeFilterState}
                                value={typeFilterState}
                                onChange={handleTypeFilterChange}
                              >
                                <VStack
                                  spacing={[1, 5]}
                                  direction={["column", "row"]}
                                  justifyContent={"start"}
                                  alignItems={"start"}
                                >
                                  <Checkbox value={"video"}>
                                    <HStack>
                                      <Box w={4} h={4}>
                                        <VideoCameraIcon />
                                      </Box>
                                      <div>360° Video</div>
                                    </HStack>
                                  </Checkbox>
                                  <Checkbox value={"app"}>
                                    <HStack>
                                      <Box w={4} h={4}>
                                        <CubeIcon />
                                      </Box>
                                      <div>Anwendung</div>
                                    </HStack>
                                  </Checkbox>
                                  <Checkbox value={"webApp"}>
                                    <HStack>
                                      <Box w={4} h={4}>
                                        <GlobeAltIcon />
                                      </Box>
                                      <div>Web App</div>
                                    </HStack>
                                  </Checkbox>
                                </VStack>
                              </CheckboxGroup>
                            </Container>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>

                      {isAuthenticated && (
                        <>
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                colorScheme={"darkBlue"}
                                borderRadius={"3xl"}
                                bgColor={ownershipFilterState.length <= 0 ? "white" : "none"}
                                size="sm"
                                variant={ownershipFilterState.length > 0 ? "solid" : "outline"}
                              >
                                Quelle <ChevronDownIcon w={"4"} h={"4"} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent borderRadius={"3xl"}>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Quelle auswählen</PopoverHeader>
                              <PopoverBody w={"full"}>
                                <Container p={"4"} w={"full"}>
                                  <CheckboxGroup
                                    defaultValue={ownershipFilterState}
                                    value={ownershipFilterState}
                                    onChange={handleOwnershipFilterChange}
                                  >
                                    <VStack
                                      spacing={[1, 5]}
                                      direction={["column", "row"]}
                                      justifyContent={"start"}
                                      alignItems={"start"}
                                    >
                                      <Checkbox value={"public"}>
                                        <HStack>
                                          <Box w={4} h={4}>
                                            <MdPublic />
                                          </Box>
                                          <Text> Öffentliche Inhalte</Text>
                                        </HStack>
                                      </Checkbox>
                                      <Checkbox value={"private"}>
                                        <HStack>
                                          <Box w={4} h={4}>
                                            <MdPublicOff />
                                          </Box>
                                          <Text> Eigene Inhalte</Text>
                                        </HStack>
                                      </Checkbox>
                                      {!hasMSBContentAcess && (
                                        <Checkbox value={"shared"}>
                                          <HStack>
                                            <Box w={4} h={4}>
                                              <FaShare />
                                            </Box>
                                            <Text> Geteilte Inhalte</Text>
                                          </HStack>
                                        </Checkbox>
                                      )}
                                      {hasMSBContentAcess && (
                                        <Checkbox value={"MSB"}>
                                          <HStack>
                                            <Box w={32} mr={"-.5rem"}>
                                              <Image src="/img/bildungsmediathek-nwr-logo.svg"></Image>
                                            </Box>
                                          </HStack>
                                        </Checkbox>
                                      )}
                                    </VStack>
                                  </CheckboxGroup>
                                </Container>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </>
                      )}

                      {atleastOneFilterIsSelected && (
                        <HStack verticalAlign={"center"}>
                          <Button
                            background={"red.500"}
                            borderRadius={"3xl"}
                            size={"sm"}
                            onClick={resetFilters}
                            _hover={{
                              bg: "red.600"
                            }}
                          >
                            Zurücksetzen
                          </Button>
                        </HStack>
                      )}
                      {isAuthenticated && renderFilter && (
                        <Link href={"/library/new"} ml="auto">
                          <Button
                            borderRadius={"3xl"}
                            size={"sm"}
                            ml={"auto"}
                            leftIcon={<PlusIcon className="w-4 h-4 text-white" />}
                          >
                            Inhalt erstellen
                          </Button>
                        </Link>
                      )}
                    </HStack>
                  </Flex>

                  {!showTagArea && categoryFilterState.length > 0 && (
                    <Box>
                      <Text mt={4} mb={2} fontWeight={600} color={"darkBlue.500"} fontSize={"sm"}>
                        Ausgewählte Tags
                      </Text>
                      <Flex flexWrap={"wrap"} gap={4}>
                        {categoryFilterState.map(categoryFilter => (
                          <Tag
                            colorScheme={categoryFilterState.includes(categoryFilter) ? "blue" : "facebook"}
                            borderRadius={"3xl"}
                            bgColor={"darkBlue.500"}
                            color={"white"}
                            key={categoryFilter.id}
                          >
                            {categoryFilter.name}
                          </Tag>
                        ))}
                      </Flex>
                    </Box>
                  )}
                </>
              )}
              <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5, "2xl": 6 }} gap={{ base: "8", lg: "12" }} mt={12}>
                {filteredContent.map((c, index) => (
                  <ContentCard key={index} content={c} />
                ))}
              </SimpleGrid>
              {filteredContent.length <= 0 && atleastOneFilterIsSelected && <NoContentFound></NoContentFound>}
            </>
          )}
        </Box>
      </Box>

      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>{modal.html}</ModalBody>

          <ModalFooter>
            <Button borderRadius="3xl" mr={3} onClick={onClose}>
              Schließen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
