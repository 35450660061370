import React from "react";
import { Box, Text } from "@chakra-ui/react";

type NoContentProps = {
  placeholderText?: string;
};
export const NoContentFound: React.FC<NoContentProps> = ({ placeholderText }) => {
  return (
    <Box p={8} w={"full"}>
      <Box
        w={"450px"}
        h={"320px"}
        mx="auto"
        style={{
          backgroundImage: `url(/img/VILPO_content_not_found.png)`,
          backgroundSize: "contain",
          backgroundPosition: "center"
        }}
      ></Box>
      <Text fontWeight={600} fontSize="xl" color={"darkBlue.500"} textAlign={"center"}>
        {placeholderText ?? "Es wurden keine Ergebnisse zu Ihrer Suchanfrage gefunden"}
      </Text>
    </Box>
  );
};
