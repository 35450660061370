import React from "react";
import styles from "./loadingDots.module.css";
export const LoadingDots: React.FC = () => {
  return (
    <span className={styles.dotsContainer}>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
    </span>
  );
};
