import { Box, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { LoadingDots } from "../loadingDots/loadingDots";

interface ILoadingSpinnerProps {
  text: string;
  isMarketplace?: boolean;
}

export default function LoadingSpinner({ text, isMarketplace = false }: ILoadingSpinnerProps) {
  return (
    <VStack>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="darkBlue.500" size="xl" />
      <Text color={isMarketplace ? "white" : "darkBlue.500"} fontWeight={600}>
        {text}
        <LoadingDots />
      </Text>
    </VStack>
  );
}
