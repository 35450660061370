import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
interface IScrollButtonProps {
  scrollBehavior?: ScrollBehavior;
  scrollThreshold?: number;
  isMarketplace?: boolean;
}
const ScrollToTopButton = ({ scrollBehavior = "smooth", scrollThreshold = 500, isMarketplace = false }: IScrollButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to check scroll position
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > scrollThreshold) {
        // Change this value to the amount of scrolling required
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: scrollBehavior
    });
  };

  return (
    <Button
      onClick={scrollToTop}
      pos="fixed"
      bgColor={"cta.500"}
      bottom={10}
      w={12}
      h={12}
      right={4}
      _hover={{ bgColor: "cta.600" }}
      size="xl"
      zIndex={999}
      display={isVisible ? "block" : "none"}
      borderRadius={99}
      cursor="pointer"
    >
      <ArrowUpIcon w={7} h={7} />
    </Button>
  );
};

export default ScrollToTopButton;
